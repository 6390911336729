import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Container, Grid, Toolbar } from '@material-ui/core';
import { LoginJwt } from '../../components/auth/login-jwt';
import { Login2fa } from '../../components/auth/login-2fa';
import { Logo } from '../../components/logo';
import { useSettings } from '../../contexts/settings-context';
import { useAuth } from '../../hooks/use-auth';
import gtm from '../../lib/gtm';

export const Login = () => {
  const { method, user, isAuthenticated } = useAuth();
  const { settings } = useSettings();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | Map Chat</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: '64px'
        }}
      >
        <Box sx={{ py: 9 }}>
          <Container maxWidth="md">
            <Grid
              container
              spacing={1}
              justifyContent="center"
            >
              <Grid
                item
                sm={8}
                xs={12}
              >
                <Container maxWidth="md">
                  <Toolbar
                    disableGutters
                    sx={{ height: 50 }}
                  >
                    <RouterLink to="/">
                      <Logo
                        variant={settings.theme === 'dark' ? 'dark' : 'light'}
                      />
                    </RouterLink>
                  </Toolbar>
                </Container>
              </Grid>
              <Grid
                item
                md={8}
                xs={12}
                sx={{ py: 0 }}
              >
                <Card
                  sx={{ backgroundColor: 'background.default' }}
                  elevation={0}
                >
                  <CardContent>
                    {method === 'JWT' && !isAuthenticated && !user?.id ? <LoginJwt /> : !isAuthenticated && user?.id && <Login2fa />}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};
