import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Container, Grid, Toolbar } from '@material-ui/core';
import { useSettings } from '../../contexts/settings-context';
import { Logo } from '../../components/logo';
import { Settings2FA } from '../../components/settings/settings-2fa';
import gtm from '../../lib/gtm';

export const TwoFactorEnable = () => {
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const { settings } = useSettings();

  return (
    <>
      <Helmet>
        <title>Two-Factor Authentication | Map Chat</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          pt: '64px'
        }}
      >
        <Box sx={{ py: 9 }}>
          <Container maxWidth="md">
            <Grid
              container
              spacing={1}
              justifyContent="center"
            >
              <Grid
                item
                md={8}
                xs={6}
              >
                <Container maxWidth="md">
                  <Toolbar
                    disableGutters
                    sx={{ height: 50 }}
                  >
                    <RouterLink to="/">
                      <Logo
                        variant={settings.theme === 'dark' ? 'dark' : 'light'}
                      />
                    </RouterLink>
                  </Toolbar>
                </Container>
              </Grid>
              <Grid
                item
                md={8}
                xs={6}
                sx={{ py: 0 }}
              >
                <Card
                  sx={{ backgroundColor: 'background.default' }}
                  elevation={0}
                >
                  <CardContent>
                    <Settings2FA mode="force" />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};
