import { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Box, Divider, Drawer, List } from '@material-ui/core';
import { DashboardSidebarItem } from './dashboard-sidebar-item';
import { Scrollbar } from './scrollbar';
import { Cog as CogIcon } from '../icons/cog';
import { CustomChartPie as ChartPieIcon } from '../icons/custom-chart-pie';
import { CustomUsers as UsersIcon } from '../icons/custom-users';
import { CapabilityGuard } from './guards/capability-guard';
import { Capabilities } from '../utils/capabilities';

const items = [
  {
    icon: ChartPieIcon,
    title: 'Environment',
    items: [
      {
        href: '/dashboard/:environmentId/environment',
        title: 'Dashboard'
      },
      {
        href: '/dashboard/:environmentId/environment/settings',
        title: 'Settings',
        capabilityGuard: Capabilities.Environment.Update
      },
      {
        href: '/dashboard/:environmentId/environment/advanced',
        title: 'API Key',
        capabilityGuard: Capabilities.Environment.View
      }
    ]
  },
  {
    icon: UsersIcon,
    title: 'Team',
    items: [
      {
        href: '/dashboard/:environmentId/team/users',
        title: 'Users',
        capabilityGuard: Capabilities.User.View
      },
      {
        href: '/dashboard/:environmentId/team/roles',
        title: 'Roles',
        capabilityGuard: Capabilities.Role.View
      }
    ]
  },
  {
    icon: CogIcon,
    title: 'Servers',
    items: [
      {
        href: '/dashboard/:environmentId/servers/',
        title: 'Server List',
        capabilityGuard: Capabilities.Environment.View
      },
      {
        href: '/dashboard/:environmentId/servers/configurations',
        title: 'Configuration Files',
        capabilityGuard: Capabilities.Environment.View
      }
    ]
  },
  {
    icon: UsersIcon,
    title: 'Accounts',
    items: [
      {
        href: '/dashboard/:environmentId/accounts',
        title: 'Search',
        capabilityGuard: Capabilities.Account.View,
      },
      {
        href: '/dashboard/:environmentId/action-requests',
        title: 'Requests',
        capabilityGuard: Capabilities.Account.View,
        counter: 'request_count'
      }
    ]
  },
];

export const DashboardSidebar = () => {
  const { pathname } = useLocation();
  // const [openedItems, setOpenedItems] = useState([]);
  const [closedItems, setClosedItems] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const [activeHref, setActiveHref] = useState('');
  // const [hovered, setHovered] = useState(false);

  const handleCloseItem = (item) => {
    if (closedItems.includes(item.title)) {
      setClosedItems((i) => (i.filter((x) => x !== item.title)));
    } else {
      setClosedItems((i) => [...i, item.title]);
    }
  };

  useEffect(() => {
    items.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            // setOpenedItems((i) => [...i, item.title]);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          setActiveItem(item);
          // setOpenedItems((i) => [...i, item.title]);
        }
      }
    });
  }, [pathname]);

  return (
    <Drawer
      open
      sx={{ zIndex: 1000 }}
      variant="permanent"
      PaperProps={{
        // onMouseOver: () => { setHovered(true); },
        // onMouseLeave: () => { setHovered(false); },
        sx: {
          backgroundColor: 'background.paper',
          height: 'calc(100% - 64px)',
          overflowX: 'hidden',
          top: 64,
          transition: 'width 250ms ease-in-out',
          width: 270,
          '& .simplebar-content': {
            height: '100%'
          },
          '&:hover': {
            width: 270,
            '& span, p': {
              display: 'flex'
            }
          }
        }
      }}
    >
      <Scrollbar
        style={{
          display: 'flex',
          flex: 1,
          overflowX: 'hidden',
          overflowY: 'auto'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            p: 2
          }}
        >
          <List disablePadding>
            {(items.map((item) => (
              <CapabilityGuard
                key={item.title}
                capability={item.capabilityGuard}
              >
                <DashboardSidebarItem
                  active={activeItem?.title === item.title}
                  activeHref={activeHref}
                  // onOpen={() => handleOpenItem(item)}
                  // open={openedItems.includes(item.title) && (hovered || pinned)}
                  onOpen={() => handleCloseItem(item)}
                  open={!closedItems.includes(item.title)}
                  pinned
                  {...item}
                />
              </CapabilityGuard>
            )))}
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <Divider />
        </Box>
      </Scrollbar>
    </Drawer>
  );
};
