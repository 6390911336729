import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import QRCode from 'qrcode';

import { Box, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { userApi } from '../../api/user';
import { useAuth } from '../../hooks/use-auth';
import { InputField } from '../input-field';
import { Settings2FADeactivateDialog } from './settings-2fa-deactivate-dialog';
import { useEnvironment } from '../../hooks/use-environment';

export const Settings2FA = ({ mode }) => {
  const [tempSecret, setTempSecret] = useState(null);
  const [otpauth, setOtpAuth] = useState(null);
  const [savedOtpAuth, setSavedOtpAuth] = useState(null);
  const [qrcode, setQrcode] = useState(null);
  const { user, accessToken, syncUser, logout } = useAuth();
  const [code2FA, setcode2FA] = useState('');
  const [deactivateOpen, setDeactivateOpen] = useState(false);
  const { environment } = useEnvironment();

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout(accessToken);
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong');
    }
  };

  const handleEnable = async () => {
    try {
      const { url2FA, secret2FA } = await userApi.generate2fa(accessToken);
      console.log(url2FA);
      // Set OTP Auth
      setOtpAuth(url2FA);
      setTempSecret(secret2FA);
      // Success
      toast.success('Two-factor authentication activated');
    } catch (err) {
      toast.error(err);
    }
  };

  const handleActivate = async () => {
    try {
      const { url2FA } = await userApi.activate2fa(
        code2FA,
        tempSecret,
        otpauth,
        accessToken
      );
      // Set OTP Auth
      setOtpAuth(null);
      setSavedOtpAuth(url2FA);
      syncUser({ ...user, url2FA });
      // Redirect
      if (mode === 'force') navigate(`/dashboard/${environment ? environment.id : 0}/environment`);
      // Success
      toast.success('Two-factor authentication activated');
    } catch (err) {
      toast.error(err);
    }
  };

  const handleDeactivate = () => {
    setOtpAuth(null);
    setSavedOtpAuth(null);
  };

  // WHen User get OTPAuthURL
  useEffect(() => {
    if (user) {
      setSavedOtpAuth(user.url2FA);
    }
  }, [user]);

  // When OTPAuth generate QRCode
  useEffect(() => {
    if (otpauth) {
      // Generate QRCode
      QRCode.toDataURL(otpauth, (err, data) => {
        if (err) toast.success('Cannot generate QR Code');
        // Set QRCode
        setQrcode(data);
      });
    }
  }, [otpauth]);

  // When OTPAuth generate QRCode
  useEffect(() => {
    if (savedOtpAuth) {
      // Generate QRCode
      QRCode.toDataURL(savedOtpAuth, (err, data) => {
        if (err) toast.success('Cannot generate QR Code');
        // Set QRCode
        setQrcode(data);
      });
    }
  }, [savedOtpAuth]);

  return (
    <Card
      variant="outlined"
      sx={{ mb: 3 }}
    >
      <CardContent>
        <Grid
          container
          spacing={mode === 'force' ? 2 : 4}
        >
          <Grid
            item
            md={mode === 'force' ? 12 : 5}
            xs={12}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              Two-factor authentication (2FA)
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {
                mode === 'force'
                  ? 'To have any form of access to the system, please enable Two-Factor authentication to enable enhanced security.'
                  : 'Enhanced security for your mention account'
              }
            </Typography>
          </Grid>
          <Grid
            item
            md={mode === 'force' ? 12 : 7}
            xs={12}
          >
            {
              otpauth
                ? (
                  <Box>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      sx={{ py: 1 }}
                    >
                      Scan QR Code to add the 2FA codes to your device.
                      Then enter the code to the field below.
                    </Typography>
                    <Box
                      sx={{ mb: 2 }}
                    >
                      <img
                        src={qrcode}
                        alt="2FA - QR Code"
                      />
                    </Box>
                    <Box>
                      <InputField
                        sx={{ mb: 2 }}
                        fullWidth
                        label="Two Factor Code"
                        name="Confirm Two Factor Authentication Code"
                        onChange={(e) => setcode2FA(e.target.value)}
                        value={code2FA}
                      />
                    </Box>
                    {
                      mode === 'force' ? (
                        <Box
                          sx={{ mr: 2 }}
                          display="inline"
                        >
                          <Button
                            onClick={handleLogout}
                            color="error"
                            size="large"
                            type="button"
                            variant="text"
                          >
                            Logout
                          </Button>
                        </Box>
                      ) : <></>
                    }
                    <Button
                      color="primary"
                      onClick={handleActivate}
                      size="large"
                      variant="outlined"
                    >
                      Activate
                    </Button>
                  </Box>
                )
                : <></>
            }
            {
              savedOtpAuth
                ? (
                  <Box>
                    {/* <Typography
                      color="textSecondary"
                      variant="body2"
                      sx={{ py: 1 }}
                    >
                      Scan QR Code to add the 2FA codes to your device.
                    </Typography>
                    <Box
                      sx={{ mb: 1 }}
                    >
                      <img
                        src={qrcode}
                        alt="2FA - QR Code"
                      />
                    </Box> */}
                    <Button
                      color="error"
                      onClick={() => setDeactivateOpen(true)}
                      size="large"
                      variant="outlined"
                    >
                      Deactivate
                    </Button>
                  </Box>
                ) : <></>
            }
            {
              !otpauth && !savedOtpAuth
                ? (
                  <>
                    {
                      mode === 'force' ? (
                        <Box
                          sx={{ mr: 2 }}
                          display="inline"
                        >
                          <Button
                            onClick={handleLogout}
                            color="error"
                            size="large"
                            type="button"
                            variant="text"
                          >
                            Logout
                          </Button>
                        </Box>
                      ) : <></>
                    }
                    <Button
                      color="primary"
                      onClick={handleEnable}
                      size="large"
                      variant="outlined"
                    >
                      Generate
                    </Button>
                  </>
                ) : <></>
            }
          </Grid>
        </Grid>
      </CardContent>
      <Settings2FADeactivateDialog
        onClose={() => setDeactivateOpen(false)}
        onConfirm={handleDeactivate}
        open={deactivateOpen}
      />
    </Card>
  );
};

Settings2FA.propTypes = {
  mode: PropTypes.string
};
