import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../api/auth';
import { useInterval } from '../hooks/use-interval';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  accessToken: null,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, accessToken, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      accessToken,
      user
    };
  },
  LOGIN: (state, action) => {
    const { userId } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      accessToken: null,
      user: { id: userId }
    };
  },
  AUTHENTICATE: (state, action) => {
    const { user, accessToken } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      accessToken,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    accessToken: null,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SYNC: (state, action) => {
    const { user } = action.payload;

    console.log({ ...state.user, ...user });

    return {
      ...state,
      user: { ...state.user, ...user }
    };
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

export const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  authenticate: () => Promise.resolve(),
  syncUser: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const addImageToUser = (dataUser) => {
    // Set User and it's image
    const { data, ...other } = dataUser;
    // Get Image From Data
    let image;
    if (data.length > 0) image = data[0].value;
    // Set Extracted Image
    if (image) return { ...other, image };
    return dataUser;
  };

  const refreshToken = async () => {
    try {
      const { accessToken, capabilities, ...other } = await authApi.refreshToken();
      let { user } = other;

      // Set User's Capabilities
      localStorage.setItem('mapmin_capabilities', JSON.stringify(capabilities));

      // Add Image Data To User
      if (user) user = addImageToUser(user);

      if (accessToken) {
        // Dispatch
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            accessToken,
            user
          }
        });

        // // Loop Refresh
        // setTimeout(() => {
        //   refreshToken();
        // }, (300 * 1000) - 500); // 500ms before 580 seconds refresh
      } else { throw new Error('Failed to generate access token.'); }
    } catch (err) {
      console.log(err);

      // Dispatch
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          accessToken: null,
          user: null
        }
      });
    }
  };

  // Refresh Token
  useEffect(() => {
    refreshToken();
  }, []);

  useInterval(refreshToken, (1000 * 300) - 500);

  const login = async (email, password) => {
    try {
      const { userId, accessToken, ...other } = await authApi.login({ email, password });
      let { user } = { ...other };

      console.log({ userId, accessToken, ...other });

      // Add Image Data To User
      if (user) user = addImageToUser(user);

      // If AccessToken then Authenticate directly
      if (accessToken) {
        dispatch({
          type: 'AUTHENTICATE',
          payload: {
            accessToken,
            user
          }
        });
      } else if (userId) { // If not then do 2FA
        dispatch({
          type: 'LOGIN',
          payload: {
            userId
          }
        });
      } else { throw new Error('Failed to login.'); }
    } catch (err) {
      console.log(err);
      // Dispatch
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          accessToken: null,
          user: null
        }
      });
      throw err;
    }
  };

  const authenticate = async (code2fa) => {
    try {
      const {
        accessToken,
        capabilities,
        ...other
      } = await authApi.authenticate({ id: state.user.id, code2fa });
      let { user } = { ...other };

      localStorage.setItem('mapmin_capabilities', JSON.stringify(capabilities));

      // Add Image Data To User
      if (user) user = addImageToUser(user);

      if (accessToken) {
        dispatch({
          type: 'AUTHENTICATE',
          payload: {
            accessToken,
            user
          }
        });
      } else { throw new Error('Failed to generate access token.'); }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const logout = async (accessToken) => {
    try {
      const { message } = await authApi.logout(accessToken);
      console.log(message);

      dispatch({ type: 'LOGOUT' });
    } catch (err) {
      console.log(err);
    }
  };

  const register = async (email, name, password) => {
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken);

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const syncUser = (dataUser) => {
    if (!dataUser) return;

    const { id: userId } = dataUser;
    const { user } = state;
    // Check If ID matches the currently logged in user
    // Then update the data of the currently logged in
    if (userId === user.id) dispatch({ type: 'SYNC', payload: { user: dataUser } });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        authenticate,
        syncUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
