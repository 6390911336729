export const Capabilities = {
  SuperAdmin: -1,
  // User Starts From 0
  User: {
    View: {
      id: 0,
      label: 'View User Data',
      description: 'The ability to view user data.'
    },
    Create: {
      id: 1,
      label: 'Create User',
      description: 'The ability to create users.'
    },
    Update: {
      id: 2,
      label: 'Update User Data',
      description: 'The ability to update user data.'
    },
    Remove: {
      id: 3,
      label: 'Remove User',
      description: 'The ability to remove users.'
    },
    Role: {
      id: 4,
      label: 'Manage Roles',
      description: 'The ability to assign and remove user roles.'
    }
  },
  // Role Starts From 100
  Role: {
    View: {
      id: 100,
      label: 'View Role',
      description: 'The ability to view role data.'
    },
    Create: {
      id: 101,
      label: 'Create Role',
      description: 'The ability to create roles.'
    },
    Update: {
      id: 102,
      label: 'Update Role Data',
      description: 'The ability to update role data.'
    },
    Remove: {
      id: 103,
      label: 'Remove Role',
      description: 'The ability to remove roles.'
    },
  },
  // Environment Starts From 200
  Environment: {
    View: {
      id: 200,
      label: 'View Environment',
      description: 'The ability to view environment data.'
    },
    Create: {
      id: 201,
      label: 'Create Environment',
      description: 'The ability to create environments.'
    },
    Update: {
      id: 202,
      label: 'Update Environment Data',
      description: 'The ability to update environment data.'
    },
    Remove: {
      id: 203,
      label: 'Remove Environment',
      description: 'The ability to remove environment.'
    },
  },
  Account: {
    View: {
      id: 300,
      label: 'View Account',
      description: 'The ability to view a account.'
    },
    RequestUpdate: {
      id: 301,
      label: 'Request Update Account',
      description: 'The ability to request to update a account.'
    },
    Update: {
      id: 302,
      label: 'Update Account',
      description: 'The ability to update a account.'
    },
    RequestBan: {
      id: 303,
      label: 'Request Update Account',
      description: 'The ability to request to ban a account.'
    },
    Ban: {
      id: 304,
      label: 'Ban Account',
      description: 'The ability to ban a account.',
    },
    RequestUnban: {
      id: 305,
      label: 'Request Update Account',
      description: 'The ability to request to unban a account.'
    },
    Unban: {
      id: 306,
      label: 'Unban Account',
      description: 'The ability to unban a account.'
    },
    RequestWhitelist: {
      id: 307,
      label: 'Request Whitelist Account',
      description: 'The ability to request to whitelist a account.'
    },
    Whitelist: {
      id: 308,
      label: 'Whitelist Account',
      description: 'The ability to whitelist a account.'
    },
    RequestUnwhitelist: {
      id: 309,
      label: 'Request Unwhitelist Account',
      description: 'The ability to request to unwhitelist a account.'
    },
    Unwhitelist: {
      id: 310,
      label: 'Unwhitelist Account',
      description: 'The ability to unwhitelist a account.'
    },
    GDPR: {
      id: 311,
      label: 'GDPR Scrub Account',
      description: 'The ability to scrub account data.'
    }
  },
  Configuration: {
    View: {
      id: 400,
      label: 'View Configuration',
      description: 'The ability to view a configuration.'
    },
    Create: {
      id: 401,
      label: 'Create Configuration',
      description: 'The ability to create a configuration.'
    },
    Update: {
      id: 402,
      label: 'Update Configuration',
      description: 'The ability to update a configuration.'
    },
    Delete: {
      id: 403,
      label: 'Delete Configuration',
      description: 'The ability to delete a configuration.'
    },
    Revert: {
      id: 404,
      label: 'Revert Configuration',
      description: 'The ability to revert a configuration from its history.'
    }
  }
};
