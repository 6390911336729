import { Container } from '@material-ui/core';
import { Outlet } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { Footer } from '../components/footer';
import { DashboardNavbar } from '../components/dashboard-navbar';

const EmptyLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  paddingTop: 64
}));

const EmptyLayoutContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}));

export const EmptyLayout = () => (
  <EmptyLayoutRoot
    sx={{
      backgroundColor: 'background.default'
    }}
  >
    <DashboardNavbar />
    <Container maxWidth="2xl">
      <EmptyLayoutContent
        sx={{
          ml: {
            md: '135px',
          },
          mr: {
            md: '135px'
          }
        }}
      >
        <Outlet />
        <Footer />
      </EmptyLayoutContent>
    </Container>
  </EmptyLayoutRoot>
);
