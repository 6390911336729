import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Typography
} from '@material-ui/core';
import { InputField } from '../input-field';
import { useAuth } from '../../hooks/use-auth';
import { userApi } from '../../api/user';
import { useEnvironment } from '../../hooks/use-environment';

export const Settings2FADeactivateDialog = (props) => {
  const { open, onClose, onConfirm, ...other } = props;
  const { accessToken } = useAuth();
  const { environment } = useEnvironment();

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().max(500).required('Code is required.'),
    }),
    onSubmit: async (values, helpers) => {
      if (values.code !== '') {
        try {
          const { message } = await userApi.disable2fa(values.code, accessToken, environment.id);

          // Confirm
          onConfirm?.();

          toast.success(message);

          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          helpers.resetForm();
          onClose?.();
        } catch (err) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err });
          helpers.setSubmitting(false);
        }
      } else {
        toast.error('Please enter 2FA Code.');
      }
    }
  });

  return (
    <Dialog
      onClose={onClose}
      open={open}
      TransitionProps={{
        onExited: () => formik.resetForm()
      }}
      {...other}
    >
      <DialogTitle>
        Disable Two-factor
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Typography>
              To disable your Two-factor Authentication please confirm and enter your 2FA code.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.code && formik.errors.code)}
              fullWidth
              helperText={formik.touched.code && formik.errors.code}
              label="2FA Code"
              name="code"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.code}
            />
          </Grid>
          {formik.errors.submit && (
            <Grid
              item
              xs={12}
            >
              <FormHelperText error>
                {formik.errors.submit}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
          variant="text"
        >
          Cancel
        </Button>
        <Button
          color="error"
          disabled={formik.isSubmitting}
          onClick={() => { formik.handleSubmit(); }}
          variant="contained"
        >
          Disable
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Settings2FADeactivateDialog.defaultProps = {
  open: false
};

Settings2FADeactivateDialog.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool
};
