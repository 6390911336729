export const lightPrimary = {
  contrast: '#ffffff',
  dark: '#38319f',
  light: '#736Be9',
  main: '#FF6431'
};

export const lightSecondary = {
  contrast: '#ffffff',
  dark: '#38319f',
  light: '#736Be9',
  main: '#6320A9'
};

export const lightError = {
  contrast: '#ffffff',
  dark: '#a53531',
  light: '#ef6f6b',
  main: '#ec4c47'
};

export const lightWarning = {
  contrast: '#ffffff',
  dark: '#975b1e',
  light: '#e09b55',
  main: '#d9822b'
};

export const lightInfo = {
  contrast: '#ffffff',
  dark: '#0b4e8d',
  light: '#3f8cd4',
  main: '#1070CA'
};

export const lightSuccess = {
  contrast: '#ffffff',
  dark: '#1b774d',
  light: '#52bb8b',
  main: '#33E871'
};

export const lightBackground = {
  default: '#ffffff',
  paper: '#ffffff'
};

export const lightText = {
  primary: '#03060b',
  secondary: '#506176'
};

export const lightNeutral = {
  100: '#f3f4f7',
  200: '#e1e3ea',
  300: '#d5d8e1',
  400: '#b7bdcc',
  500: '#9aa1b7',
  600: '#7c85a2',
  700: '#3f455a',
  800: '#2a2f3c',
  900: '#1e212a'
};

export const darkPrimary = {
  contrast: '#ffffff',
  dark: '#568ab2',
  light: '#95d1ff',
  main: '#FF6431'
};

export const darkSecondary = {
  contrast: '#101010',
  dark: '#eeeeee',
  light: '#ffffff',
  main: '#6320A9'
};

export const darkError = {
  contrast: '#ffffff',
  dark: '#85372b',
  light: '#eb6d57',
  main: '#AB2E3C'
};

export const darkWarning = {
  contrast: '#ffffff',
  dark: '#8D682d',
  light: '#f7bb57',
  main: '#FFC107'
};

export const darkInfo = {
  contrast: '#ffffff',
  dark: '#1D4580',
  light: '#4483df',
  main: '#0D6EFD'
};

export const darkSuccess = {
  contrast: '#ffffff',
  dark: '#2F6b39',
  light: '#5fc16d',
  main: '#33E871'
};

export const darkBackground = {
  default: '#080808',
  paper: '#191c24'
};

export const hyperButton = {
  contrast: '#101010',
  dark: '#eeeeee',
  light: '#ffffff',
  main: '#ffffff'
};

export const hyperBackground = {
  default: 'linear-gradient(to left, #5B26DA, #631FA1)'
};

export const darkText = {
  primary: '#d8dade',
  secondary: '#868998'
};

export const darkNeutral = {
  100: '#0E1014',
  200: '#1D2129',
  300: '#2A313D',
  400: '#384151',
  500: '#465065',
  600: '#546079',
  700: '#62708D',
  800: '#73819E',
  900: '#8793AC'
};
