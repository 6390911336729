import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

export const Logo = (props) => {
  const { emblemOnly, both, variant, size, mr } = props;

  const logo = variant === 'light' ? '/static/peek-logo-light.png' : '/static/peek-logo-dark.png';
  const icon = variant === 'light' ? '/static/peek-logo-light.png' : '/static/peek-logo-dark.png';

  if (both) {
    return (
      <>
        <Box
          component="img"
          src={icon}
          height={size}
          alt="Map Chat Icon"
          sx={{ mr: 1 }}
        />
        <img
          src={logo}
          height={size}
          alt="Map Chat Logo"
        />
      </>
    );
  }

  if (emblemOnly) {
    return (
      <img
        src={icon}
        width={size}
        alt="Map Chat Icon"
      />
    );
  }
  return (
    <Box sx={{ mr }}>
      <img
        src={logo}
        height={size}
        alt="Map Chat Logo"
      />
    </Box>
  );
};

Logo.defaultProps = {
  emblemOnly: false,
  variant: 'dark',
  both: false,
  size: '30px',
  mr: '0px'
};

Logo.propTypes = {
  emblemOnly: PropTypes.bool,
  variant: PropTypes.oneOf(['light', 'dark']),
  both: PropTypes.bool,
  size: PropTypes.string,
  mr: PropTypes.string
};
