import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';

export const UserAvatar = ({ image, serverImage, height, width }) => {
  // Image To Show
  let imageToShow;
  if (image) imageToShow = `${process.env.REACT_APP_STATIC_SERVER}/${image}`;
  else if (serverImage) imageToShow = '/static/user-random.png';
  else imageToShow = '/static/user-random.png';

  return (
    <Avatar
      src={imageToShow}
      variant="rounded"
      sx={{
        height,
        width
      }}
    />
  );
};

UserAvatar.defaultProps = {
  height: 40,
  width: 40
};

UserAvatar.propTypes = {
  serverImage: PropTypes.bool,
  image: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
