export const ObjectType = {
  User: 0,
  Role: 1,
  Environment: 2,
  Configuration: 3,
  Account: 4,
  Server: 5
};

export const getObjectTypeUrl = (objectType, objectId, environmentId) => {
  let url = '';
  switch (+objectType) {
    case ObjectType.User:
      url = `/dashboard/${environmentId}/team/users/${objectId}`;
      break;
    case ObjectType.Role:
      url = `/dashboard/${environmentId}/team/roles/${objectId}`;
      break;
    case ObjectType.Environment:
      url = `/dashboard/${environmentId}/environment/settings`;
      break;
    case ObjectType.Configuration:
      url = `/dashboard/${environmentId}/servers/configurations`;
      break;
    case ObjectType.Account:
      url = `/dashboard/${environmentId}/accounts/view/${objectId}`;
      break;
    case ObjectType.Server:
      url = `/dashboard/${environmentId}/servers/${objectId}`;
      break;
    default:
      url = '';
      break;
  }
  return url;
};
