import { useState, useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Drawer, List } from '@material-ui/core';
import { DashboardNavbarMenuItem } from './dashboard-navbar-menu-item';
import { Cog as CogIcon } from '../icons/cog';
import { CustomChartPie as ChartPieIcon } from '../icons/custom-chart-pie';
import { CustomUsers as UsersIcon } from '../icons/custom-users';
import { Capabilities } from '../utils/capabilities';

const items = [
  {
    icon: ChartPieIcon,
    title: 'Environment',
    items: [
      {
        href: '/dashboard/:environmentId/environment',
        title: 'Dashboard'
      },
      {
        href: '/dashboard/:environmentId/environment/settings',
        title: 'Settings',
        capabilityGuard: Capabilities.Environment.Update
      },
      {
        href: '/dashboard/:environmentId/environment/advanced',
        title: 'API Key',
        capabilityGuard: Capabilities.Environment.View
      }
    ]
  },
  {
    icon: UsersIcon,
    title: 'Team',
    items: [
      {
        href: '/dashboard/:environmentId/team/users',
        title: 'Users',
        capabilityGuard: Capabilities.User.View
      },
      {
        href: '/dashboard/:environmentId/team/users/disabled',
        title: 'Disabled',
        capabilityGuard: Capabilities.SuperAdmin
      },
      {
        href: '/dashboard/:environmentId/team/roles',
        title: 'Roles',
        capabilityGuard: Capabilities.Role.View
      }
    ]
  },
  {
    icon: CogIcon,
    title: 'Servers',
    items: [
      {
        href: '/dashboard/:environmentId/servers/',
        title: 'Server List',
        capabilityGuard: Capabilities.Environment.View
      },
      {
        href: '/dashboard/:environmentId/servers/configurations',
        title: 'Configuration Files',
        capabilityGuard: Capabilities.Environment.View
      }
    ]
  },
  {
    icon: UsersIcon,
    title: 'Accounts',
    items: [
      {
        href: '/dashboard/:environmentId/accounts',
        title: 'Search',
        capabilityGuard: Capabilities.Account.View,
      },
      {
        href: '/dashboard/:environmentId/action-requests',
        title: 'Requests',
        capabilityGuard: Capabilities.Account.View,
        counter: 'request_count'
      }
    ]
  },
];

export const DashboardNavbarMenu = (props) => {
  const { open, onClose } = props;
  const { pathname } = useLocation();
  const [openedItem, setOpenedItem] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [activeHref, setActiveHref] = useState('');

  const handleOpenItem = (item) => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  useEffect(() => {
    items.forEach((item) => {
      if (item.items) {
        for (let index = 0; index < item.items.length; index++) {
          const active = matchPath({ path: item.items[index].href, end: true }, pathname);

          if (active) {
            setActiveItem(item);
            setActiveHref(item.items[index].href);
            setOpenedItem(item);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: item.href, end: true }, pathname);

        if (active) {
          setActiveItem(item);
          setOpenedItem(item);
        }
      }
    });
  }, [pathname]);

  return (
    <Drawer
      anchor="top"
      onClose={onClose}
      open={open}
      transitionDuration={0}
      ModalProps={{
        BackdropProps: {
          invisible: true
        }
      }}
      PaperProps={{
        sx: {
          backgroundColor: '#2B2F3C',
          color: '#B2B7C8',
          display: 'flex',
          flexDirection: 'column',
          top: 64,
          maxHeight: 'calc(100% - 64px)',
          width: '100vw'
        }
      }}
    >
      <List>
        {activeItem && (items.map((item) => (
          <DashboardNavbarMenuItem
            active={activeItem?.title === item.title}
            activeHref={activeHref}
            key={item.title}
            onClose={onClose}
            onOpenItem={() => handleOpenItem(item)}
            open={openedItem?.title === item.title}
            {...item}
          />
        )))}
      </List>
    </Drawer>
  );
};

DashboardNavbarMenu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};
