import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { DashboardLayout } from './layouts/dashboard-layout';
import { EmptyLayout } from './layouts/empty-layout';

import { AuthGuard } from './components/guards/auth-guard';
import { GuestGuard } from './components/guards/guest-guard';
import { EnvironmentGuard } from './components/guards/environment-guard';
import { CapabilityGuard } from './components/guards/capability-guard';
import { LoadingScreen } from './components/loading-screen';
import { Capabilities } from './utils/capabilities';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Not found pages
const NotFound = Loadable(lazy(() => import('./layouts/not-found').then((module) => ({ default: module.NotFound }))));

// Auth pages
const Login = Loadable(lazy(() => import('./containers/auth/login').then((module) => ({ default: module.Login }))));
const PasswordRecovery = Loadable(lazy(() => import('./containers/auth/password-recovery').then((module) => ({ default: module.PasswordRecovery }))));
const PasswordReset = Loadable(lazy(() => import('./containers/auth/password-reset').then((module) => ({ default: module.PasswordReset }))));
const VerifyCode = Loadable(lazy(() => import('./containers/auth/verify-code').then((module) => ({ default: module.VerifyCode }))));

// Dashboard pages
const EnvironmentDashboardContainer = Loadable(lazy(() => import('./containers/environment/environment-dashboard-container').then((module) => ({ default: module.EnvironmentDashboardContainer }))));
const EnvironmentDashboard = Loadable(lazy(() => import('./containers/environment/environment-dashboard').then((module) => ({ default: module.EnvironmentDashboard }))));
const EnvironmentSettings = Loadable(lazy(() => import('./containers/environment/environment-settings').then((module) => ({ default: module.EnvironmentSettings }))));
const EnvironmentApi = Loadable(lazy(() => import('./containers/environment/environment-api').then((module) => ({ default: module.EnvironmentApi }))));
const EnvironmentSelector = Loadable(lazy(() => import('./containers/environment/environment-selector').then((module) => ({ default: module.EnvironmentSelector }))));
const EnvironmentCreate = Loadable(lazy(() => import('./containers/environment/environment-create').then((module) => ({ default: module.EnvironmentCreate }))));

// Configuration
const Configurations = Loadable(lazy(() => import('./containers/configuration/configurations').then((module) => ({ default: module.Configurations }))));
const ConfigurationCreate = Loadable(lazy(() => import('./containers/configuration/configuration-create').then((module) => ({ default: module.ConfigurationCreate }))));
const ConfigurationView = Loadable(lazy(() => import('./containers/configuration/configuration-view').then((module) => ({ default: module.ConfigurationView }))));
const ConfigurationSummary = Loadable(lazy(() => import('./containers/configuration/configuration-summary').then((module) => ({ default: module.ConfigurationSummary }))));
const ConfigurationActivity = Loadable(lazy(() => import('./containers/configuration/configuration-activity').then((module) => ({ default: module.ConfigurationActivity }))));
const ConfigurationContent = Loadable(lazy(() => import('./containers/configuration/configuration-content').then((module) => ({ default: module.ConfigurationContent }))));

// Servers
const Servers = Loadable(lazy(() => import('./containers/server/servers').then((module) => ({ default: module.Servers }))));
const ServerView = Loadable(lazy(() => import('./containers/server/server-view').then((module) => ({ default: module.ServerView }))));
const ServerSummary = Loadable(lazy(() => import('./containers/server/server-summary').then((module) => ({ default: module.ServerSummary }))));
const ServerGraphs = Loadable(lazy(() => import('./containers/server/server-graphs').then((module) => ({ default: module.ServerGraphs }))));
const ServerActivity = Loadable(lazy(() => import('./containers/server/server-activity').then((module) => ({ default: module.ServerActivity }))));
const ServerIssues = Loadable(lazy(() => import('./containers/server/server-issues').then((module) => ({ default: module.ServerIssues }))));

// Team
const TeamContainer = Loadable(lazy(() => import('./containers/team-container').then((module) => ({ default: module.TeamContainer }))));
const Users = Loadable(lazy(() => import('./containers/user/users').then((module) => ({ default: module.Users }))));
const UserCreate = Loadable(lazy(() => import('./containers/user/user-create').then((module) => ({ default: module.UserCreate }))));
const UserView = Loadable(lazy(() => import('./containers/user/user-view').then((module) => ({ default: module.UserView }))));
const UserActivity = Loadable(lazy(() => import('./containers/user/user-activity').then((module) => ({ default: module.UserActivity }))));
const UserSummary = Loadable(lazy(() => import('./containers/user/user-summary').then((module) => ({ default: module.UserSummary }))));
const UserRoles = Loadable(lazy(() => import('./containers/user/user-roles').then((module) => ({ default: module.UserRoles }))));
const UserEdit = Loadable(lazy(() => import('./containers/user/user-edit').then((module) => ({ default: module.UserEdit }))));

// Users
const Accounts = Loadable(lazy(() => import('./containers/account/accounts').then((module) => ({ default: module.Accounts }))));
const AccountView = Loadable(lazy(() => import('./containers/account/account-view').then((module) => ({ default: module.AccountView }))));
const AccountSummary = Loadable(lazy(() => import('./containers/account/account-summary').then((module) => ({ default: module.AccountSummary }))));
const AccountActivity = Loadable(lazy(() => import('./containers/account/account-activity').then((module) => ({ default: module.AccountActivity }))));
const AccountRequests = Loadable(lazy(() => import('./containers/account/account-requests').then((module) => ({ default: module.AccountRequests }))));

// Action Requests
const ActionRequests = Loadable(lazy(() => import('./containers/action-request/action-requests').then((module) => ({ default: module.ActionRequests }))));

// Roles
const Roles = Loadable(lazy(() => import('./containers/role/roles').then((module) => ({ default: module.Roles }))));
const RoleCreate = Loadable(lazy(() => import('./containers/role/role-create').then((module) => ({ default: module.RoleCreate }))));
const RoleView = Loadable(lazy(() => import('./containers/role/role-view').then((module) => ({ default: module.RoleView }))));
const RoleSummary = Loadable(lazy(() => import('./containers/role/role-summary').then((module) => ({ default: module.RoleSummary }))));
const RoleCapabilities = Loadable(lazy(() => import('./containers/role/role-capabilities').then((module) => ({ default: module.RoleCapabilities }))));
const RoleActivity = Loadable(lazy(() => import('./containers/role/role-activity').then((module) => ({ default: module.RoleActivity }))));

// My Account Settings
const Settings = Loadable(lazy(() => import('./containers/settings/settings').then((module) => ({ default: module.Settings }))));
const SettingsGeneral = Loadable(lazy(() => import('./containers/settings/settings-general').then((module) => ({ default: module.SettingsGeneral }))));
const SettingsNotifications = Loadable(lazy(() => import('./containers/settings/settings-notifications').then((module) => ({ default: module.SettingsNotifications }))));

const routes = [
  {
    path: '/',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: 'verify-code',
    element: (
      <GuestGuard>
        <VerifyCode />
      </GuestGuard>
    )
  },
  {
    path: 'password-recovery',
    element: (
      <GuestGuard>
        <PasswordRecovery />
      </GuestGuard>
    )
  },
  {
    path: 'password-reset',
    element: (
      <PasswordReset />
    )
  },
  {
    path: '/environment',
    element: (
      <AuthGuard>
        <EmptyLayout>
          <EnvironmentDashboardContainer />
        </EmptyLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: '/selector',
        element: <EnvironmentSelector />
      },
      {
        path: '/create',
        element: (
          <CapabilityGuard
            capability={Capabilities.Environment.Create}
            redirect
          >
            <EnvironmentCreate />
          </CapabilityGuard>
        )
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <EnvironmentGuard>
          <DashboardLayout />
        </EnvironmentGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: ':environmentId',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="environment"
                replace
              />
            )
          },
          {
            path: '/environment',
            element: <EnvironmentDashboardContainer />,
            children: [
              {
                path: '/',
                element: <EnvironmentDashboard />
              },
              {
                path: '/settings',
                element: (
                  <CapabilityGuard
                    capability={Capabilities.Environment.Update}
                    redirect
                  >
                    <EnvironmentGuard>
                      <EnvironmentSettings />
                    </EnvironmentGuard>
                  </CapabilityGuard>
                )
              },
              {
                path: '/settings',
                element: (
                  <CapabilityGuard
                    capability={Capabilities.Environment.Update}
                    redirect
                  >
                    <EnvironmentGuard>
                      <EnvironmentSettings />
                    </EnvironmentGuard>
                  </CapabilityGuard>
                )
              },
              {
                path: '/advanced',
                element: (
                  <CapabilityGuard
                    capability={Capabilities.Environment.View}
                    redirect
                  >
                    <EnvironmentGuard>
                      <EnvironmentApi />
                    </EnvironmentGuard>
                  </CapabilityGuard>
                )
              },
            ]
          },
          {
            path: '/team',
            element: (
              <EnvironmentGuard>
                <TeamContainer />
              </EnvironmentGuard>
            ),
            children: [
              {
                path: '/users',
                children: [
                  {
                    path: '/',
                    element: (
                      <CapabilityGuard
                        capability={Capabilities.User.View}
                        redirect
                      >
                        <Users status />
                      </CapabilityGuard>
                    )
                  },
                  {
                    path: '/disabled',
                    element: (
                      <CapabilityGuard
                        capability={Capabilities.User.View}
                        redirect
                      >
                        <Users status={false} />
                      </CapabilityGuard>
                    )
                  },
                  {
                    path: '/create',
                    element: (
                      <CapabilityGuard
                        capability={Capabilities.User.Create}
                        redirect
                      >
                        <UserCreate />
                      </CapabilityGuard>
                    )
                  },
                  {
                    path: ':userId',
                    element: (
                      <CapabilityGuard
                        capability={Capabilities.User.View}
                        redirect
                      >
                        <UserView />
                      </CapabilityGuard>
                    ),
                    children: [
                      {
                        path: '/',
                        element: <UserSummary />
                      },
                      {
                        path: 'activity',
                        element: <UserActivity />
                      },
                      {
                        path: 'roles',
                        element: <UserRoles />
                      },
                      {
                        path: 'edit',
                        element: (
                          <CapabilityGuard
                            capability={Capabilities.User.Update}
                            redirect
                          >
                            <UserEdit />
                          </CapabilityGuard>
                        )
                      }
                    ]
                  }
                ]
              },
              {
                path: '/roles',
                children: [
                  {
                    path: '/',
                    element: <Roles />
                  },
                  {
                    path: '/create',
                    element: (
                      <CapabilityGuard
                        capability={Capabilities.Role.Create}
                        redirect
                      >
                        <RoleCreate />
                      </CapabilityGuard>
                    )
                  },
                  {
                    path: ':roleId',
                    element: (
                      <CapabilityGuard
                        capability={Capabilities.Role.Update}
                        redirect
                      >
                        <RoleView />
                      </CapabilityGuard>
                    ),
                    children: [
                      {
                        path: '/',
                        element: <RoleSummary />
                      },
                      {
                        path: 'activity',
                        element: <RoleActivity />
                      },
                      {
                        path: 'capabilities',
                        element: <RoleCapabilities />
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: '/servers',
            element: (
              <EnvironmentGuard>
                <TeamContainer />
              </EnvironmentGuard>
            ),
            children: [
              {
                path: '/',
                element: (
                  <CapabilityGuard
                    capability={Capabilities.Environment.View}
                    redirect
                  >
                    <EnvironmentGuard>
                      <Servers />
                    </EnvironmentGuard>
                  </CapabilityGuard>
                )
              },
              {
                path: ':serverId',
                element: (
                  <ServerView />
                ),
                children: [
                  {
                    path: '/',
                    element: (
                      <ServerSummary />
                    ),
                  },
                  {
                    path: '/graphs',
                    element: (
                      <ServerGraphs />
                    )
                  },
                  {
                    path: '/activity',
                    element: (
                      <ServerActivity />
                    )
                  },
                  {
                    path: '/issues',
                    element: (
                      <ServerIssues />
                    )
                  }
                ]
              },
              {
                path: '/configurations',
                children: [
                  {
                    path: '/',
                    element: (
                      <CapabilityGuard
                        capability={Capabilities.Environment.View}
                        redirect
                      >
                        <EnvironmentGuard>
                          <Configurations />
                        </EnvironmentGuard>
                      </CapabilityGuard>
                    )
                  },
                  {
                    path: 'create',
                    element: (
                      <ConfigurationCreate />
                    )
                  },
                  {
                    path: ':configurationId',
                    element: (
                      <ConfigurationView />
                    ),
                    children: [
                      {
                        path: '/',
                        element: <ConfigurationSummary />
                      },
                      {
                        path: 'activity',
                        element: <ConfigurationActivity />
                      },
                      {
                        path: 'content',
                        element: <ConfigurationContent />
                      }
                    ]
                  }
                ]
              },
            ]
          },
          {
            path: '/accounts',
            element: (
              <EnvironmentGuard>
                <TeamContainer />
              </EnvironmentGuard>
            ),
            children: [
              {
                path: '/',
                element: <Accounts />
              },
              {
                path: '/search/:query',
                element: <Accounts />
              },
              {
                path: '/view/:accountId',
                element: <AccountView />,
                children: [
                  {
                    path: '/',
                    element: <AccountSummary />
                  },
                  {
                    path: 'activity',
                    element: <AccountActivity />
                  },
                  {
                    path: 'requests',
                    element: <AccountRequests />
                  }
                ]
              },
            ]
          },
          {
            path: 'action-requests',
            element: (
              <EnvironmentGuard>
                <TeamContainer />
              </EnvironmentGuard>
            ),
            children: [
              {
                path: '/',
                element: <ActionRequests />
              }
            ]
          },
          {
            path: 'settings',
            element: <Settings />,
            children: [
              {
                path: '/',
                element: <SettingsGeneral />
              },
              {
                path: 'notifications',
                element: <SettingsNotifications />
              }
            ]
          },
          {
            path: '*',
            element: <NotFound />
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: (
      <DashboardLayout>
        <NotFound />
      </DashboardLayout>
    )
  }
];

export default routes;
