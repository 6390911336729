export const TagTypes = [
  'Live',
  'Dev',
  'Alpha',
  'Beta',
  'Other'
];

export const TagTypeColors = [
  'primary', // Color For Live
  'secondary', // Color For Dev
  'success', // Color For Alpha
  'error', // Color For Beta
  'background', // Color For Other
];
