import { useEffect, useState } from 'react';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Collapse, List, Typography } from '@material-ui/core';
import { ChevronRight as ChevronRightIcon } from '../icons/chevron-right';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';
import { ExternalLink as ExternalLinkIcon } from '../icons/external-link';
import { CapabilityGuard } from './guards/capability-guard';
import { actionRequestApi } from '../api/action-request';
import { useAuth } from '../hooks/use-auth';
import { useEnvironment } from '../hooks/use-environment';
import { ObjectType } from '../utils/log-resource';
import { TextAnimation } from './text-animation';

export const DashboardSidebarItem = (props) => {
  const {
    active,
    activeHref,
    external,
    href,
    icon: Icon,
    items,
    onOpen,
    open,
    pinned,
    title,
    counter
  } = props;
  const { t } = useTranslation();
  const { accessToken } = useAuth();
  const [count, setCount] = useState({});
  const location = useLocation();
  const { environment } = useEnvironment();

  // EnvironmentId
  let environmentId;
  const match = matchPath({ path: '/dashboard/:environmentId/*', strict: false }, location.pathname);
  if (match) ({ environmentId } = match.params);

  useEffect(async () => {
    if (!environment) return;

    items.forEach(async (item) => {
      if (item.counter === 'request_count') {
        const { count: itemCount } = await actionRequestApi.getCount({
          objectType: ObjectType.Account
        }, accessToken, environment.id);
        // count
        setCount({ ...count, [`${item.title}_${item.counter}`]: itemCount });
      }
    });
  }, [environment]);

  // Branch
  if (items) {
    return (
      <List
        disablePadding
        sx={{ width: '100%' }}
      >
        <li>
          <Button
            endIcon={open
              ? <ChevronDownIcon fontSize="small" />
              : <ChevronRightIcon fontSize="small" />}
            fullWidth
            onClick={onOpen}
            startIcon={<Icon />}
            sx={{
              justifyContent: 'flex-start',
              lineHeight: 0,
              minWidth: 'fit-content',
              px: 1.25,
              py: 1.25,
              '& .MuiButton-startIcon': {
                color: active ? 'text.primary' : 'text.secondary',
                margin: 0
              },
              '& .MuiButton-endIcon': {
                color: 'action.disabled',
                display: pinned ? 'flex' : 'none',
                marginLeft: 'auto'
              },
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.10)'
              }
            }}
            variant="text"
          >
            <Typography
              color="textPrimary"
              sx={{
                color: active ? 'text.primary' : 'text.primary',
                display: pinned ? 'flex' : 'none',
                ml: 1.25
              }}
              variant="inherit"
            >
              { counter ? `${t(title)} (${count})` : t(title) }
            </Typography>
          </Button>
        </li>
        <Collapse
          in={open}
          unmountOnExit
        >
          <List
            disablePadding
            sx={{ width: '100%' }}
          >
            {items.map((item) => {
              const isActive = activeHref === item.href;
              const { capabilityGuard } = item;
              const newHref = item.href ? item.href.replace('/:environmentId', `/${environmentId}`) : '';

              return (
                <CapabilityGuard
                  key={item.href}
                  capability={capabilityGuard}
                >
                  <li key={item.href}>
                    <Button
                      component={RouterLink}
                      fullWidth
                      sx={{
                        color: isActive ? 'text.primary' : 'text.secondary',
                        fontWeight: 400,
                        justifyContent: 'flex-start',
                        pl: 5,
                        whiteSpace: 'nowrap',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.10)'
                        }
                      }}
                      to={newHref}
                      variant="text"
                    >
                      {count[`${item.title}_${item.counter}`] > 0 ? (
                        <TextAnimation>
                          { `${t(item.title)} (${count[`${item.title}_${item.counter}`]})` }
                        </TextAnimation>
                      ) : t(item.title)}
                    </Button>
                  </li>
                </CapabilityGuard>
              );
            })}
          </List>
        </Collapse>
      </List>
    );
  }

  // Leaf
  return (
    <li>
      <Button
        component={RouterLink}
        endIcon={external && <ExternalLinkIcon sx={{ color: 'action.disabled' }} />}
        fullWidth
        startIcon={<Icon />}
        target={external ? '_target' : '_self'}
        sx={{
          justifyContent: 'flex-start',
          lineHeight: 0,
          minWidth: 'fit-content',
          px: 1.25,
          py: 1.25,
          '& .MuiButton-startIcon': {
            color: active ? 'primary' : 'text.secondary',
            margin: 0
          },
          '& .MuiButton-endIcon': {
            color: 'action.disabled',
            display: pinned ? 'flex' : 'none',
            marginLeft: 'auto'
          }
        }}
        to={href}
        variant="text"
      >
        <Typography
          color="textPrimary"
          sx={{
            color: active ? 'primary' : 'text.primary',
            display: pinned ? 'flex' : 'none',
            ml: 1.25
          }}
          variant="inherit"
        >
          {t(title)}
        </Typography>
      </Button>
    </li>
  );
};

DashboardSidebarItem.defaultProps = {
  open: false,
  pinned: false
};

DashboardSidebarItem.propTypes = {
  active: PropTypes.bool,
  activeHref: PropTypes.string,
  external: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  items: PropTypes.array,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
  pinned: PropTypes.bool,
  title: PropTypes.string,
  counter: PropTypes.string
};
